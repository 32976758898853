import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Home3Component } from './home3/home3.component';
import { Contact1Component } from './pages/contact1/contact1.component';
import { NewsGridComponent } from './blog/news-grid/news-grid.component';
import { LoadingComponent } from './elements/loading/loading.component';
import { ScrollToTopComponent } from './elements/scroll-to-top/scroll-to-top.component';
import { Header3Component } from './elements/header/header3/header3.component';
import { NavLinksComponent } from './elements/nav-links/nav-links.component';
import { Footer1Component } from './elements/footer/footer1/footer1.component';
import { Slider3Component } from './elements/rev-slider/slider3/slider3.component';
import { Testimonial1Component } from './elements/testimonial/testimonial1/testimonial1.component';
import { Blog1Component } from './elements/blog/blog1/blog1.component';
import { CallToAction1Component } from './elements/call-to-action/call-to-action1/call-to-action1.component';
import { Specialization3Component } from './elements/specialization/specialization3/specialization3.component';
import { Banner1Component } from './elements/banners/banner1/banner1.component';

@NgModule({
  declarations: [
    AppComponent,
    Home3Component,
    Contact1Component,
    NewsGridComponent,
    LoadingComponent,
    ScrollToTopComponent,
    Header3Component,
    NavLinksComponent,
    Footer1Component,
    Slider3Component,
    Testimonial1Component,
    Blog1Component,
    CallToAction1Component,
    Specialization3Component,
    Banner1Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
