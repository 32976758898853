import { Component, OnInit } from '@angular/core';
declare  var jQuery: any;

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.css']
})
export class ScrollToTopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    (($) => {

      setTimeout(() => {
          jQuery('button.scroltop').on('click', () => {
            jQuery('html, body').animate({
              scrollTop: 0
            }, 1000);
            return false;
          });

          jQuery(window).on('scroll', () => {
            const scroll = jQuery(window).scrollTop();
            if (scroll > 900) {
              jQuery('button.scroltop').fadeIn(1000);
            } else {
              jQuery('button.scroltop').fadeOut(1000);
            }
          });
      }, 500);
    })(jQuery);
  }

}
