<div class="section-full bg-white p-t80">
    <div class="container ">

        <!-- TITLE START -->
        <div class="section-head text-left">
            <h4 class="text-primary text-uppercase wt-title-small">Our Specialization</h4>
            <h2 class="text-uppercase font-45 font-weight-500 m-b5">Typical Products</h2>
        </div>
        <!-- TITLE END -->

    </div>
    <div class="container">

        <div class="section-content">

            <div class="row">
                <div class="col-md-6 bg-cover wt-half-left-outer">
                    <div class="m-t30">
                        <div class="wt-media">
                            <img src="assets/images/spec1.jpg" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 wt-half-right-outer m-t30 bg-gray">
                    <div class="wt-half-right p-a50 p-l50 p-r0">
                        <!-- TITLE START -->
                        <div class="section-head text-left">
                            <h2 class="text-uppercase font-36 font-weight-500 m-b5">{{data[0].section_heading}}
                            </h2>
                            <p>{{data[0].section_description}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 bg-cover wt-half-left-outer">
                    <div class="m-t30">
                        <div class="wt-media">
                            <img src="assets/images/spec2.jpg" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 wt-half-right-outer m-t30 bg-gray">
                    <div class="wt-half-right p-a50 p-l50 p-r0">
                        <!-- TITLE START -->
                        <div class="section-head text-left">
                            <h2 class="text-uppercase font-36 font-weight-500 m-b5">{{data[1].section_heading}}
                            </h2>
                            <p>{{data[1].section_description}}<br><br></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container m-t50">
        <!-- TITLE START -->
        <div class="section-head text-left">
            <h4 class="text-primary text-uppercase wt-title-small">Our Specialization</h4>
            <h2 class="text-uppercase font-45 font-weight-500 m-b5">logistics services</h2>
        </div>
        <!-- TITLE END -->
    </div>
    <div class="container">
        <div class="section-content">
            <div class="row">
                <div class="col-md-6 bg-cover wt-half-left-outer">
                    <div class="m-t30">
                        <div class="wt-media">
                            <img src="assets/images/logistic.jpg" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 wt-half-right-outer m-t30 bg-gray">
                    <div class="wt-half-right p-a50 p-l50 p-r0">
                        <!-- TITLE START -->
                        <div class="section-head text-left">
                            <h2 class="text-uppercase font-36 font-weight-500 m-b5">{{data[2].section_heading}}
                            </h2>
                            <p>{{data[2].section_description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>