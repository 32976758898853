import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Home3Component } from './home3/home3.component';
import { Contact1Component } from './pages/contact1/contact1.component';
import { NewsGridComponent } from './blog/news-grid/news-grid.component';

const routes: Routes = [
                {path: '', component: Home3Component},
                {path: 'index', component: Home3Component},
                {path: 'index-3', component: Home3Component},
                {path: 'home-3', component: Home3Component},
                {path: 'contact-1', component: Contact1Component},
                {path: 'news-grid', component: NewsGridComponent},
              ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
