<div class="section-full small-device p-t80 bg-white">
  <ng-template ngFor let-sectionData [ngForOf]="data">
    <div class="container">
      
          <!-- TITLE START -->
          <div class="section-head text-left">
              <h4 class="text-primary text-uppercase wt-title-small">{{sectionData.section_title}}</h4>
              <h2 class="text-uppercase font-45 font-weight-500 m-b5">{{sectionData.section_heading}}</h2>
          </div>
          <!-- TITLE END -->  

          <!-- IMAGE CAROUSEL START -->
          <div class="section-content">
              <div class="row">
                  <div class="col-md-4 col-sm-6" *ngFor = "let item of sectionData.items">
                      <div class="blog-post latest-blog-1 date-style-1">
                          <div class="wt-post-media wt-img-effect zoom-slow">
                              <a href="javascript:void(0);"><img src="assets/images/blog/latest-blog/{{item.blog_image}}" alt=""></a>
                          </div>
                          <div class="wt-post-info  bg-white">
                          
                                  <div class="wt-post-meta ">
                                      <ul>
                                          <li class="post-author"><i class="fa fa-user"></i><strong>By {{item.post_author}}</strong></li>                                                
                                          <li class="post-date"><i class="fa fa-calendar"></i><strong>{{item.post_date}} </strong></li>
                                      </ul>
                                  </div>                                    
                                  <div class="wt-post-title ">
                                      <h4 class="post-title"><a routerLink="/{{item.read_more_link}}">{{item.post_title}}</a></h4>
                                  </div>
                                  <div class="wt-post-readmore">
                                      <a routerLink="/{{item.read_more_link}}" class="text-primary">{{item.read_more_text}}</a>
                                  </div>
                             
                          </div>
                      </div>
                  </div>                        
                                                                                                
              </div>
          </div>
    </div>
  </ng-template>
</div>   