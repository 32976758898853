<div class="section-full small-device p-t80 bg-white">
    <ng-template ngFor let-sectionData [ngForOf]="data">
        <div class="container">
            <div class="section-content">

                <!-- TITLE START -->
                <div class="section-head text-left">
                    <h4 class="text-primary text-uppercase wt-title-small">{{sectionData.section_title}}</h4>
                    <h2 class="text-uppercase font-45 font-weight-500 m-b5">{{sectionData.section_heading}}</h2>
                </div>
                <!-- TITLE END -->

                <!-- TESTIMONIAL 4 START ON BACKGROUND -->
                <div class="section-content">
                    <!-- <div class="owl-carousel testimonial-home"> -->
                    <owl-carousel-o [options]="customOptions" class="owl-carousel-1 testimonial-home-1">
                        <ng-template carouselSlide *ngFor="let item of sectionData.testimonial">
                            <div class="item">
                                <div class="testimonial-5 bg-white">
                                    <div class="testimonial-pic-block radius-bx">
                                        <div class="testimonial-pic radius">
                                            <img src="assets/images/testimonials/{{item.image}}" width="132"
                                                height="132" alt="">
                                        </div>
                                    </div>
                                    <div class="testimonial-text clearfix">
                                        <div class="testimonial-paragraph">
                                            <span class="fa fa-quote-right text-primary"></span>
                                            <p>{{item.description}}</p>
                                        </div>
                                        <div class="testimonial-detail clearfix">
                                            <strong class="testimonial-name text-black">{{item.name}}</strong>
                                            <span class="testimonial-position p-t20">{{item.role}}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                    <!-- <div class="item">
                        <div class="testimonial-5 bg-white">
                            <div class="testimonial-pic-block radius-bx"> 
                                <div class="testimonial-pic radius">
                                    <img src="assets/images/testimonials/pic2.jpg" width="132" height="132" alt="">
                                </div>
                            </div>
                            <div class="testimonial-text clearfix">
                            <div class="testimonial-paragraph">
                                <span class="fa fa-quote-right text-primary"></span>
                                <p>Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent taciti sociosqu ad litora torquent per conubia nostra.
                                </p>
                            </div>
                            <div class="testimonial-detail clearfix">
                                <strong class="testimonial-name text-black">RINDA SMITH</strong>
                                <span class="testimonial-position p-t20">Founder</span>
                            </div>
                        </div>
                            
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimonial-5 bg-white">
                            <div class="testimonial-pic-block radius-bx"> 
                                <div class="testimonial-pic radius">
                                    <img src="assets/images/testimonials/pic1.jpg" width="132" height="132" alt="">
                                </div>
                            </div>
                            <div class="testimonial-text clearfix">
                            <div class="testimonial-paragraph">
                                <span class="fa fa-quote-right text-primary"></span>
                                <p>Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent taciti sociosqu ad litora torquent per conubia nostra.
                                </p>
                            </div>
                            <div class="testimonial-detail clearfix">
                                <strong class="testimonial-name text-black">RINDA SMITH</strong>
                                <span class="testimonial-position p-t20">Founder</span>
                            </div>
                        </div>
                            
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimonial-5 bg-white">
                            <div class="testimonial-pic-block radius-bx"> 
                                <div class="testimonial-pic radius">
                                    <img src="assets/images/testimonials/pic1.jpg" width="132" height="132" alt="">
                                </div>
                            </div>
                            <div class="testimonial-text clearfix">
                            <div class="testimonial-paragraph">
                                <span class="fa fa-quote-right text-primary"></span>
                                <p>Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent taciti sociosqu ad litora torquent per conubia nostra.
                                </p>
                            </div>
                            <div class="testimonial-detail clearfix">
                                <strong class="testimonial-name text-black">RINDA SMITH</strong>
                                <span class="testimonial-position p-t20">Contractor</span>
                            </div>
                        </div>
                            
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimonial-5 bg-white">
                            <div class="testimonial-pic-block radius-bx"> 
                                <div class="testimonial-pic radius">
                                    <img src="assets/images/testimonials/pic2.jpg" width="132" height="132" alt="">
                                </div>
                            </div>
                            <div class="testimonial-text clearfix">
                            <div class="testimonial-paragraph">
                                <span class="fa fa-quote-right text-primary"></span>
                                <p>Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent taciti sociosqu ad litora torquent per conubia nostra.
                                </p>
                            </div>
                            <div class="testimonial-detail clearfix">
                                <strong class="testimonial-name text-black">RINDA SMITH</strong>
                                <span class="testimonial-position p-t20">Founder</span>
                            </div>
                        </div>
                            
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimonial-5 bg-white">
                            <div class="testimonial-pic-block radius-bx"> 
                                <div class="testimonial-pic radius">
                                    <img src="assets/images/testimonials/pic1.jpg" width="132" height="132" alt="">
                                </div>
                            </div>
                            <div class="testimonial-text clearfix">
                            <div class="testimonial-paragraph">
                                <span class="fa fa-quote-right text-primary"></span>
                                <p>Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent taciti sociosqu ad litora torquent per conubia nostra.
                                </p>
                            </div>
                            <div class="testimonial-detail clearfix">
                                <strong class="testimonial-name text-black">RINDA SMITH</strong>
                                <span class="testimonial-position p-t20">Founder</span>
                            </div>
                        </div>
                            
                        </div>
                    </div> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </ng-template>
</div>