<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-header3></app-header3>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
        <!-- INNER PAGE BANNER -->
        <app-banner1 [data]="banner"></app-banner1>
        <!-- INNER PAGE BANNER END -->
        
  <!-- SECTION CONTENT START -->
        <div class="section-full small-device p-tb80 bg-gray">
          <!-- <div class="container"> -->
                <!-- PAGINATION START -->
                <!-- <div class="filter-wrap p-tb50 text-center">
                    <ul class="masonry-filter link-style  text-uppercase">
                            <li class="active"><a data-filter="*" href="#">All</a></li>
                            <li><a data-filter=".cat-1" href="#">House</a></li>
                            <li><a data-filter=".cat-2" href="#">Building</a></li>
                            <li><a data-filter=".cat-3" href="#">Office</a></li>
                            <li><a data-filter=".cat-4" href="#">Garden</a></li>
                            <li><a data-filter=".cat-5" href="#">Interior</a></li>
                    </ul>
                </div> -->
                <!-- PAGINATION END -->
          <!-- </div> -->
            
            <!-- GALLERY CONTENT START -->
            <div class="portfolio-wrap mfp-gallery news-grid clearfix">
                    <div class="row">
                        <!-- COLUMNS 1 -->
                        <div class="masonry-item {{item.category}} col-lg-4 col-md-6 col-sm-6 m-b30 blog-grid-1" *ngFor = "let item of news_grid_section">
                            <div class="wt-img-effect ">
                                <img src="assets/images/gallery/{{item.blog_image}}" alt="">
                            </div>
                            <div class="wt-post-info  bg-white" style="margin-top: 0;margin: 0;">
                                <div class="wt-post-meta ">
                                    <ul>
                                        <li class="post-date" [innerHTML]="item.post_date"></li>
                                        <li class="post-author"><i class="fa fa-user"></i><a href="mailto:trading@qdneo.com"><span>{{item.post_author}}</span></a> </li>
                                    </ul>
                                </div>                                        
                                <div class="wt-post-title ">
                                    <h3 class="post-title"><a routerLink="/{{item.read_more_link}}" class=" font-weight-600 m-t0">{{item.post_title}}</a></h3>
                                </div>
                                <div class="wt-post-text">
                                    <p>{{item.description}}</p> 
                                    <p><img src="assets/images/gallery/{{item.price}}" alt=""></p>
                                </div>
                                <!-- <a routerLink="/{{item.read_more_link}}" class="btn-half site-button site-btn-effect button-md"><span>{{item.read_more_text}}</span></a> -->
                            </div>
                        </div>                                                                                           
                    </div>
            </div>
            <!-- GALLERY CONTENT END -->
        
        </div>
        <!-- SECTION CONTENT END  -->

    </div>
    <!-- CONTENT END -->
               
    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->

    <!-- BUTTON TOP START -->
<app-scroll-to-top></app-scroll-to-top>
    
</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>