import { Component, OnInit } from '@angular/core';
declare  var jQuery:  any;

@Component({
  selector: 'app-home3',
  templateUrl: './home3.component.html',
  styleUrls: ['./home3.component.css']
})
export class Home3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
	  (function ($) {
      setTimeout(function(){
          
          jQuery('.site-footer').css('display', 'block');
          jQuery('.site-footer').css('height', 'auto');
          var footerHeight = jQuery('.site-footer').outerHeight();
          jQuery('.footer-fixed.page-wraper').css('padding-bottom', footerHeight);
          jQuery('.site-footer').css('height', footerHeight);
        }, 500);
    })(jQuery);
  
  }
  icon_section = [
    {
      section_title: "",
      section_heading: "",
      section_description:"",
      
      items: [
        {
          icon_class: "flaticon-paint-brushes",
          title: "Blanking Line",
          catgegory: "",
          heading: "",
          description: "",
          read_more_text: "Read More",
          read_more_link: "about-1",
         
        },
        {
          icon_class: "flaticon-skyline",
          title: "Precision Punching Line",
          catgegory: "",
          heading: "",
          description: "",
          read_more_text: "Read More",
          read_more_link: "about-1",
        },
        {
          icon_class: "flaticon-sketch",
          title: "Flying Shear Cutting Line",
          catgegory: "",
          heading: "",
          description: "",
          read_more_text: "Read More",
          read_more_link: "about-1",
        },
        {
          icon_class: "flaticon-crane",
          title: "Longitudinal Cutting Line",
          catgegory: "",
          heading: "",
          description: "",
          read_more_text: "Read More",
          read_more_link: "about-1",
        },
        
      ]
    }
  ];
  
  company_product_section = [
    {
      section_heading: "GAS TAPS LIFE TEST BENCH",
      section_description:"This test bench is to make life test on the gas taps used in Hobs and Oven. The user can create test programs with simple instructions. The machine controls torque and pressing force, can also makes leak tests and CT simulation. During the tests, it records all errors that occur and a test report is printed at the end. We are European leaders in this test bench and all manufacturers, users and certification offices use our equipment.",
    },
    {
      section_heading: "Stamping and Coil Processing",
      section_description:"In order to achieve better parts accuracy, all processing equipment adopts imported brands, and all processing centers choose imported peaks and Litz brands. All parts implement a full inspection policy, from product cutting, machining, assembly, and equipment acceptance, etc., to establish quality control in each link of production, which can realize the traceability of the quality of each process.",
    },
    {
      section_heading: "Cross-border services",
      section_description:"NEO Logistics is a cross-border logistics service designed specifically for the manufacturing industry, providing enterprises with globally diversified transportation resources, intelligent and efficient product experiences, and rich guarantee services throughout the entire chain. Our value creation is reflected in shortening customer transaction time, reducing transaction costs, and improving the overall operational efficiency of the enterprise.",
    },
  ];
  clients : any = [
      {
        image: "w1.png",
        title: "",
        url: "",
      },
      {
        image: "w2.png",
        title: "",
        url: "",
      },
      {
        image: "w3.png",
        title: "",
        url: "",
      },
      {
        image: "w4.png",
        title: "",
        url: "",
      },
      {
        image: "w5.png",
        title: "",
        url: "",
      },
      {
        image: "w6.png",
        title: "",
        url: "",
      },
  ];
  our_work_section = [
    {
      section_title: "Recently finished",
      section_heading: "Recent projects",
      section_description:"",
      class_val:"",
      section_class:"",
      
      items: [
        {
          image: "pic1.jpg",
          catgegory: "",
          heading: "",
          title: "Automobile Precision Punching Line",
          description: "TUL precision leveling line series, material thickness 2mm-12mm, material width 60-500, suitable for high-tension material correction, the correction section adopts single correction roller adjustment, which can achieve excellent stress removal ability.",
          read_more_text: "Read More",
          read_more_link: "project-detail",
         
        },
        {
          image: "pic2.jpg",
          catgegory: "",
          heading: "",
          title: "Flying Shear Cutting Production Line ",
          description: "The double feeding station is equipped with a precision replaceable straightening system, suitable for the production of materials with a large span, and can achieve good material flatness, ensuring excellent product quality.",
          read_more_text: "Read More",
          read_more_link: "project-detail",
        },
        {
          image: "pic3.jpg",
          catgegory: "",
          heading: "",
          title: "High-speed Longitudinal Cutting Production Line",
          description: "High-speed longitudinal cutting series, implementing European standards, all control systems are more intelligent, and can better improve enterprise profits.",
          read_more_text: "Read More",
          read_more_link: "project-detail",
        },
        {
          image: "pic4.jpg",
          catgegory: "",
          heading: "",
          title: "Ultra-high-speed Deflection Feeder",
          description: "In the field of deflection feeder, focus on the ultra-thin, ultra-thick and ultra-wide field, implement high-precision and high-speed feeding mode, and fill the gap in the field of deflection ultra-high-speed feeding in China.",
          read_more_text: "Read More",
          read_more_link: "project-detail",
        },
        {
          image: "pic5.jpg",
          catgegory: "",
          heading: "",
          title: "Double-stage Fine Punching Line",
          description: "TUL-24-400 double-stage precision leveling machine and German FEINT00L precision punching machine, two-stage precision straightening, can achieve better material stress removal effect.",
          read_more_text: "Read More",
          read_more_link: "project-detail",
        },
        {
          image: "pic6.jpg",
          catgegory: "",
          heading: "",
          title: "High-Efficiency Feeding Double-Head Unwinder",
          description: "Excellent double-head unwinder feeding system, can quickly change the coil, reduce the feeding time and improve work efficiency.",
          read_more_text: "Read More",
          read_more_link: "project-detail",
        },
      ]
    }
  ];
  
  our_team = [
    {
      section_title: "Our best team",
      section_heading: "Our team",
      section_description: "",
      
      team: [
        {
          name: "Mariya Newman",
          role: "Project Manager",
          image: "pic5.jpg",
          social_link: [
            {
              title:"facebook",
              icon_class:"fa-facebook",
              link:"https://www.facebook.com/dexignzone",
            },
            {
              title:"twitter",
              icon_class:"fa-twitter",
              link:"https://twitter.com/dexignzones",
            },
            {
              title:"linkedin",
              icon_class:"fa-linkedin",
              link:"https://www.linkedin.com/showcase/dexignzone",
            },
            {
              title:"pinterest",
              icon_class:"fa-pinterest",
              link:"https://www.pinterest.com/dexignzone/",
            }
          ]
        },
        {
          name: "Michael evens",
          role: "Marketing manager",
          image: "pic3.jpg",
          social_link: [
            {
              title:"facebook",
              icon_class:"fa-facebook",
              link:"https://www.facebook.com/dexignzone",
            },
            {
              title:"twitter",
              icon_class:"fa-twitter",
              link:"https://twitter.com/dexignzones",
            },
            {
              title:"linkedin",
              icon_class:"fa-linkedin",
              link:"https://www.linkedin.com/showcase/dexignzone",
            },
            {
              title:"pinterest",
              icon_class:"fa-pinterest",
              link:"https://www.pinterest.com/dexignzone/",
            }
          ]
        },
        {
          name: "Devead Newman",
          role: "Project Manager",
          image: "pic1.jpg",
          social_link: [
            {
              title:"facebook",
              icon_class:"fa-facebook",
              link:"https://www.facebook.com/dexignzone",
            },
            {
              title:"twitter",
              icon_class:"fa-twitter",
              link:"https://twitter.com/dexignzones",
            },
            {
              title:"linkedin",
              icon_class:"fa-linkedin",
              link:"https://www.linkedin.com/showcase/dexignzone",
            },
            {
              title:"pinterest",
              icon_class:"fa-pinterest",
              link:"https://www.pinterest.com/dexignzone/",
            }
          ]
        },
        {
          name: "Pamela Smith",
          role: "Project Manager",
          image: "pic2.jpg",
          social_link: [
            {
              title:"facebook",
              icon_class:"fa-facebook",
              link:"https://www.facebook.com/dexignzone",
            },
            {
              title:"twitter",
              icon_class:"fa-twitter",
              link:"https://twitter.com/dexignzones",
            },
            {
              title:"linkedin",
              icon_class:"fa-linkedin",
              link:"https://www.linkedin.com/showcase/dexignzone",
            },
            {
              title:"pinterest",
              icon_class:"fa-pinterest",
              link:"https://www.pinterest.com/dexignzone/",
            }
          ]
        },
      ]
    }
  ];
  
  testimonials = [
    {
      section_title: "Client Says",
      section_heading: "Testimonials",
      section_description: "",
      
      testimonial: [
        {
          name: "Daniel Maina",
          role: "Manager",
          image: "pic1.jpg",
          description: "A stable product line has improved our production efficiency while effectively reducing production costs, bringing higher profits to the enterprise.",
        },
        {
          name: "Joachim Masira",
          role: "Manager",
          image: "pic3.jpg",
          description: "The efficient service level has solved various problems encountered in the production process for us, greatly promoting the development and production progress of the company's new products.",
        },
      ]
    }
  ];
  
  blogs = [
    {
      section_title: "Recent blog",
      section_heading: "Latest News",
      section_description: "",
      
      items: [
        {
          title: "",
          blog_image: "pic1.jpg",
          post_date:"30 Jun 2023",
          post_author: "Admin",
          post_title: "Fully automatic production line for washing machine drum.",
          read_more_text: "Read More",
          read_more_link: "post-gallery",
        },
        {
          title: "",
          blog_image: "pic2.jpg",
          post_date:"20 Aug 2023",
          post_author: "Admin",
          post_title: "Fully automatic production line for laundry case body.",
          read_more_text: "Read More",
          read_more_link: "post-gallery",
        },
        {
          title: "",
          blog_image: "pic3.jpg",
          post_date:"20 Sep 2023",
          post_author: "Admin",
          post_title: "Automatic production line for shell of washing machine",
          read_more_text: "Read More",
          read_more_link: "post-gallery",
        },
      ]  
    }

  ];

}
