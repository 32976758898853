<ul class=" nav navbar-nav">
  <li class="active">
      <a routerLink="/index">Home</a>                                               
  </li>

  <li>
      <a href="javascript:void(0);">News</a>
      <ul class="sub-menu">
          <li><a routerLink="/news-grid">News Grid</a></li>
      </ul>
  </li>
  <li>
    <a href="javascript:void(0);">Contact us</a>
    <ul class="sub-menu">
        <li>
            <a routerLink="/contact-1">Get in touch</a>
        </li>
    </ul>
</li>                                      
</ul>