<div class="section-full small-device p-tb80 bg-white">
  <div class="container ">
      <!-- IMAGE CAROUSEL START -->
      <div class="row">
        <div class="col-md-6 m-b30">
              <!-- TITLE START -->
              <div class="text-left">
                  <h2 class="text-uppercase font-45 font-weight-500 m-b5">Continuous improvement and innovation </h2>
                  <p>We are a team with dreams. We come from all over the world with a common dream: to be a professional automation non-standard enterprise and provide customers with advanced production equipment.</p>
                  <a routerLink="/about-1" class="site-button site-btn-effect">Read More</a>
              </div>
              <!-- TITLE END -->                        
          </div>
          
          <div class="col-md-6">
            <div class="video-section-outer">
                <div class="video-section">
                      <img src="assets/images/gallery/pic1.jpg" alt="">
                       <!-- <a href="https://player.vimeo.com/video/34741214?color=ffffff&title=0&byline=0&portrait=0" class="mfp-video play-now">
                          <i class="icon fa fa-play"></i>
                          <span class="ripple"></span>
                      </a>                                               -->
                  </div>
              </div>
          </div>
  
              
       </div>
  </div>
</div>   