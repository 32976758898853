<footer class="site-footer footer-large  footer-light	footer-wide text-black">
  
  <!-- FOOTER BLOCKES START -->  
  <div class="footer-top overlay-wraper">
      <div class="overlay-main"></div>
      <div class="container">
          <div class="row">

            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="widget widget_address_outer m-b20">
                    <h4 class="widget-title">QINGDAO NEO TRADING CO. LTD.</h4>
                    <ul class="widget_address">
                        <li>Building 14, MAX Science Park,<br>No. 17 Guangbo Road, High tech Zone, Qingdao City</li>
                        <li>info@qdneo.com / serena@qdneo.com</li>
                        <li>(+86) 18753288003</li>
                    </ul>
                    <ul class="social-icons f-social-link">
                      <li><a href="javascript:void(0);" class="fa fa-google"></a></li>
                      <li><a href="javascript:void(0);" class="fa fa-rss"></a></li>
                      <li><a href="javascript:void(0);" class="fa fa-facebook"></a></li>
                      <li><a href="javascript:void(0);" class="fa fa-twitter"></a></li>
                      <li><a href="javascript:void(0);" class="fa fa-linkedin"></a></li>
                  </ul>    
                </div>
            </div>

              <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="widget widget_services inline-links">
                      <h4 class="widget-title">TEST BENCH Products</h4>
                      <ul>
                          <li><a routerLink="/gtltb">GAS TAPS LIFE TEST BENCH</a></li>
                          <li><a routerLink="/tltb">THERMOCOUPLES LIFE TEST BENCH</a></li>
                          <li><a routerLink="/ttb">THERMOSTATS TEST BENCH</a></li>
                      </ul>
                  </div>                           
              </div>      

              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="widget widget_services inline-links">
                    <h4 class="widget-title">STAMPING EQUIPMENT AND COIL PROCESSING</h4>
                    <ul>
                        <li><a routerLink="/abl">Automotive blanking line</a></li>
                        <li><a routerLink="/appl">Automobile Precision Punching Line</a></li>
                        <li><a routerLink="/cpl">Cutting Production Line</a></li>
                        <li><a routerLink="/dds">Deflection Deeder Series</a></li>
                    </ul>
                </div>                           
            </div>      

          </div>
          <div class="m-b10">
              <div class="wt-divider bg-gray-dark"><i class="icon-dot c-square"></i></div>
          </div>
          <!-- <div class="news-letter-footer">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="newsletter-f-left">
                          <h4 class="text-uppercase m-t0 m-b10">Subscribe to our newsletter!</h4>
                          <p>Never Miss Anything From Construx By Signing Up To Our Newsletter. </p>
                      </div>
                  </div>    
                  <div class="col-md-6 col-sm-6">
                      <div class="newsletter-f-right text-center">
                          <form role="search">
                              <div class="input-group">
                                  <input  class="form-control" placeholder="ENTER YOUR EMAIL" type="text">
                                  <span class="input-group-btn">
                                      <button type="submit" class="site-button site-btn-effect"><span>Submit</span></button>
                                  </span>
                              </div>
                           </form>
                      </div>
                  </div>
              </div>                           
          </div> -->
      </div>
  </div>
  <!-- FOOTER COPYRIGHT -->
  <div class="footer-bottom overlay-wraper">
      <div class="overlay-main"></div>
      <div class="container">
          <div class="row">
              <div class="wt-footer-bot-center">
                  <span class="copyrights-text">© 2023 QINGDAO NEO TRADING CO.,LTD.</span>
              </div>
          </div>
      </div>
  </div>
</footer>