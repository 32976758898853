import { Component, OnInit } from '@angular/core';
declare  var jQuery: any;

@Component({
  selector: 'app-news-grid',
  templateUrl: './news-grid.component.html',
  styleUrls: ['./news-grid.component.css']
})
export class NewsGridComponent implements OnInit {

  banner: any = {
    pagetitle: 'Stamping & Riveting',
    bg_image: '2.jpg',
    title: 'Stamping & Riveting',
  };
  news_grid_section = [
        {
          blog_image: 'pic1.jpg',
          post_date: '<strong>Dec</strong> <span> 2024</span>',
          post_author: 'trading@qdneo.com',
          post_title: 'Universal CEC type',
          description: 'Air conditioning terminal - single point riveting machine',
          price: 'price1.jpg',
          category: 'cat-2'
        },
        {
          blog_image: 'pic2.jpg',
          post_date: '<strong>DEC</strong> <span> 2024</span>',
          post_author: 'trading@qdneo.com',
          post_title: 'TCEU type air duct fan',
          description: 'Air conditioning terminal - single point riveting machine',
          price: 'price2.jpg',
          category: 'cat-2'
        },
        {
          blog_image: 'pic3.png',
          post_date: '<strong>DEC</strong> <span> 2024</span>',
          post_author: 'trading@qdneo.com',
          post_title: 'Universal CEU type',
          description: 'Air conditioning terminal - single point riveting machine',
          price: 'price3.png',
          category: 'cat-2'
        },
        {
          blog_image: 'pic4.png',
          post_date: '<strong>DEC</strong> <span> 2024</span>',
          post_author: 'trading@qdneo.com',
          post_title: 'Hand pliers CRH type',
          description: 'Air conditioning terminal - single point riveting machine',
          price: 'price4.png',
          category: 'cat-2'
        },
        {
          blog_image: 'pic5.png',
          post_date: '<strong>DEC</strong> <span> 2024</span>',
          post_author: 'trading@qdneo.com',
          post_title: 'Corner riveting machine',
          description: 'Air conditioning terminal - corner 4/6-point riveting machine without rivets',
          price: 'price5.png',
          category: 'cat-2'
        },
        {
          blog_image: 'pic6.png',
          post_date: '<strong>DEC</strong> <span> 2024</span>',
          post_author: 'trading@qdneo.com',
          post_title: 'Frame height riveting equipment',
          description: 'Air conditioning terminal - corner height riveting machine without rivets',
          price: 'price6.png',
          category: 'cat-2'
        },
        {
          blog_image: 'pic7.png',
          post_date: '<strong>DEC</strong> <span> 2024</span>',
          post_author: 'trading@qdneo.com',
          post_title: 'Bracket two-point riveting equipment',
          description: 'Air conditioning terminal - air valve bracket two-point riveting special machine',
          price: 'price7.png',
          category: 'cat-2'
        },
        {
          blog_image: 'pic8.png',
          post_date: '<strong>DEC</strong> <span> 2024</span>',
          post_author: 'trading@qdneo.com',
          post_title: 'Windshield single point riveting equipment',
          description: 'Air conditioning terminal - single point riveting machine for air valve and windshield strip',
          price: 'price8.png',
          category: 'cat-2'
        },

  ];
  constructor() { }
  ngOnInit(): void {
    (($) => {
      setTimeout(() => {
         const $container = jQuery('.portfolio-wrap');
         $container.isotope({
           itemSelector: '.masonry-item',
           transitionDuration: '1s',
           originLeft: true,
           stamp: '.stamp'
                });

         jQuery('.masonry-filter li').on('click', function(): boolean {
                const selector = jQuery(this).find('a').attr('data-filter');
                jQuery('.masonry-filter li').removeClass('active');
                jQuery(this).addClass('active');
                $container.isotope({ filter: selector });
                return false;
            });

      }, 500);
    })(jQuery);

  }

}
